import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import MainTitle from '../components/uielements/MainTitle'
import BlogSlider from '../components/buildingBlocks/BlogSlider'
import Spacer from '../components/uielements/Spacer'
import Image from '../components/uielements/Image'
import Columns from '../components/uielements/Columns'
import ReadMore from '../components/buildingBlocks/ReadMore'
import Wrapper from '../components/Wrapper'
import { Helmet } from 'react-helmet'
import Seo from '../components/seo'
import RichContent from '../components/uielements/RichContent/richContent'
import { useTranslation } from 'react-i18next'

const Page = ({ data: { page } }) => {
    const { i18n } = useTranslation()

    return (
        <Layout>
            <Helmet>
                <meta property="og:title" content={page.title} />
            </Helmet>
            <Wrapper>
                <Seo title={page.title} />
                <MainTitle>{page.title}</MainTitle>
                <Spacer top="5" md={{ top: '2' }} />
                <Columns>
                    <Image obj={page.featuredImage} />
                    <RichContent body={page?.body?.raw} />
                    {i18n.language === 'el-GR' && <ReadMore {...page} />}
                </Columns>
                <Spacer top="15" md={{ top: '1' }} />
                <BlogSlider />
            </Wrapper>
        </Layout>
    )
}

export const query = graphql`
    query fetchPage($slug: String) {
        page: contentfulPage(slug: { eq: $slug }) {
            id
            slug
            title
            body {
                raw
            }
            readMore {
                slug
                title
                postType
            }
            node_locale
            featuredImage {
                url
                title
                gatsbyImageData(
                    quality: 75
                    jpegProgressive: true
                    sizes: "1920, 960, 480"
                    cropFocus: CENTER
                )
            }
        }
    }
`

export default Page
